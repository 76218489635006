import React, { Component } from "react";
import "./App.css";
import dayjs from "dayjs";

class App extends Component {
  state = {
    more: false,
    scrollTop: 0,
    showProducts: false,
  };
  render() {
    return (
      <div
        id="exuus-site"
        onScroll={(e) => {
          this.setState({ scrollTop: e.target.scrollTop, showProducts: false });
        }}
      >
        <div
          id="header"
          className={this.state.scrollTop < 16 ? "" : "scrolled"}
        >
          <div id="logo">
            <a href="/">
              <img src={require("./images/exuus_logo.svg")} alt="" />
            </a>
          </div>
          <div style={{ display: "flex", flex: 1 }}>&nbsp;</div>
        </div>
        <div id="body">
          <div className="left">
            <h1 className="welcome">Welcome!</h1>
            <p>
              Exuus inspires resilience and enables communities to achieve their
              optimal satisfaction by positively impacting the future.
              {this.state.more ? (
                ""
              ) : (
                <span
                  className="more"
                  onClick={() => this.setState({ more: true })}
                >
                  {" "}
                  more
                </span>
              )}
            </p>
            {!this.state.more ? (
              ""
            ) : (
              <p>
                We endeavour to create products that are ideal in every possible
                way. Products that excite, that are easy to use and powerful.
              </p>
            )}
            {!this.state.more ? (
              ""
            ) : (
              <p>
                The same passion for innovation translates into our zeal to grow
                as an eco-friendly company – therefore, throughout our
                engagements with clients, we raise awareness for environmental
                responsibility.
              </p>
            )}
            <p className="signature">
              <img src={require("./images/signature_exuus.svg")} alt="" />
            </p>
          </div>
          <div className="right">
            <img src={require("./images/illustration2.svg")} alt="" />
            <img
              className="illustration-mobile"
              src={require("./images/mobile_image.svg")}
              alt=""
            />
            <a
              href="http://getsave.io"
              target="_blank"
              className="product-link"
            >
              <span>Check out our product</span>
              <img
                className="save-logo"
                src={require("./images/save-logo.svg")}
                alt=""
              />
            </a>
          </div>
        </div>
        <div id="footer">
          <div id="menu">
            <a
              href="https://twitter.com/exuusLtd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/twitter_2.svg")} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/exuus-ltd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/linkedin_2.svg")} alt="" />
            </a>
            <a href="mailto:info@exuus.com" target="_top">
              <img src={require("./images/envelope_2.svg")} alt="" />
            </a>
          </div>
          <div style={{ display: "flex", flex: 1 }}>&nbsp;</div>
          <div id="menu" className="copy">
            <span>Copyright &copy; {dayjs().year()}</span>
            <a href="/">
              <img src={require("./images/exuus_footer.svg")} alt="" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
